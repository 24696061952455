<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitHandler"
  >
    <v-card-title>
      <span class="card-title">Калькулятор ферросплавов</span>
    </v-card-title>
    <v-card-text>
      <v-alert type="error" outlined dense>
        Срок действия лицензии истек
      </v-alert>
      <v-text-field
        v-model.trim="key"
        :rules="licenseRules"
        label="Ключ лицензии"
        required
      ></v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        type="submit"
        block
        :disabled="!valid"
        color="teal lighten-1"
        class="mt-1 mb-5 white--text"
        @click="validate"
      >
        Добавить
        <v-icon right dark class="login-btn">mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      valid: true,
      licenseRules: [(v) => !!v || "Это поле необходимо заполнить"],
      key: "",
    };
  },
  computed: {
    ...mapState({
      user: (x) => x.auth.user,
    }),
  },
  methods: {
    ...mapActions(["setLicenseKey", "getUser"]),
    async submitHandler() {
      try {
        await this.setLicenseKey({ key: this.key });
        await this.getUser();

        this.$refs.form.reset();

        if (this.user.login && !this.user.licenseIsTimeout)
          this.$router.push("/");
      } catch (e) {}
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style>
</style>