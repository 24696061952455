<template>
  <v-card elevation="2" class="auth-card">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submitHandler"
      v-if="!inputLicenseKey"
    >
      <v-card-title>
        <span class="card-title">Калькулятор ферросплавов</span>
      </v-card-title>
      <v-card-text>
        <v-alert
          type="error"
          outlined
          dense
          v-show="login_error.error || licenseError.error"
        >
          {{
            licenseError.error
              ? licenseError.errorMessage
              : login_error.errorMessage
          }}
        </v-alert>
        <v-text-field
          v-model.trim="name"
          :rules="nameRules"
          label="Логин"
          required
        ></v-text-field>

        <v-text-field
          v-model.trim="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          label="Пароль"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          type="submit"
          block
          :disabled="!valid"
          color="teal lighten-1"
          class="mt-1 mb-5 white--text"
          @click="validate"
        >
          Войти
          <v-icon right dark class="login-btn">mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
    <div v-if="inputLicenseKey">
      <license></license>
    </div>
  </v-card>
</template>

<script>
import messages from "@/utils/messages";
import { mapActions, mapMutations, mapState } from "vuex";
import License from "@/components/License.vue";

export default {
  name: "login",
  components: {
    License,
  },
  data() {
    return {
      valid: true,
      showPassword: false,
      minChars: 6,
      name: "",
      nameRules: [(v) => !!v || "Это поле необходимо заполнить"],
      password: "",
      passwordRules: [
        (v) => !!v || "Это поле необходимо заполнить",
        (v) =>
          v.length >= this.minChars ||
          `Пароль должен быть ${this.minChars} символов. Сейчас он ${this.password.length}`,
      ],
      licenseError: {
        error: false,
        errorMessage:
          "Срок действия лицензии истек. Обратитесь к администратору",
      },
      inputLicenseKey: false,
    };
  },
  computed: {
    ...mapState({
      login_error: (s) => s.auth.login_error,
      user: (s) => s.auth.user,
      is_admin: (s) => s.auth.is_admin,
      license_exp_date: (x) => x.auth.license.exp_date,
    }),
  },
  async mounted() {
    try {
      await this.getLicenseExpDate();
      this.notify();
    } catch (e) {}
  },
  methods: {
    ...mapActions(["login", "getLicenseExpDate"]),
    ...mapMutations(["SET_LOGIN_ERROR"]),
    async submitHandler() {
      const formData = {
        name: this.name,
        password: this.password,
      };

      try {
        await this.login(formData);
        if (this.user.login && !this.login_error.error) {
          if (this.user.licenseIsTimeout) {
            if (this.is_admin) this.inputLicenseKey = true;
            else this.licenseError.error = true;
          } else {
            this.$router.push("/");
          }
        }
      } catch (e) {}
    },
    notify() {
      let licenseIsTimeout = new Date(this.license_exp_date) < new Date();

      if (licenseIsTimeout) {
        this.$error("Срок действия лицензии истек", "");
      } else {
        let message = messages[this.$route.query.message];

        if (message) this.$message(message);
      }
    },
    validate() {
      if (this.login_error.error) this.SET_LOGIN_ERROR(false);

      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.login-btn {
  position: absolute;
  right: 0;
}
.auth-card {
  width: 500px;
}
.card-title {
  font-size: 24px;
  font-weight: 300;
}
</style>